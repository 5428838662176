import { css } from '@emotion/react';

const styles = (theme) => css`
  background-color: ${theme.palette.supply.cream.main};
  padding: 0 0 8rem;
  overflow: hidden;
  ${theme.breakpoints.up('md')} {
    padding-bottom: 25rem;
  }

  h3 {
    font-family: 'Bogue-Regular';
    font-size: 3rem;
    margin-bottom: 2rem;
  }

  .top-bottom-spacing {
    margin: 8rem 0;
  }

  .limit-width {
    max-width: 720px;
  }

  .big-photo-section {
    .gatsby-image-wrapper {
      margin-bottom: ${theme.spacing(3)};
    }
    p {
      max-width: 480px;
    }
  }

  .two-up {
    .gatsby-image-wrapper {
      height: 400px;
    }
  }

  .img-lr {
    width: 100%;
    max-height: 500px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const blogHeroStyles = (theme) => css`
  background-color: ${theme.palette.supply.cream.main};
  padding: 0 0 8rem;
  overflow: hidden;
  ${theme.breakpoints.up('md')} {
    padding-bottom: 25rem;
  }

  ${theme.breakpoints.between(
    theme.breakpoints.values.sm,
    theme.breakpoints.values.md
  )} {
    height: 100vh;
  }

  .subhead {
    font-size: 2rem;
    max-width: 720px;
    margin-top: 5.5rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 2.5rem;
  }

  .img-container {
    position: relative;
    z-index: 9;
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-top: 3rem;
    img {
      max-width: 180px;
    }
  }

  .rich-text-section {
    position: relative;
    z-index: 2;
    text-align: center;
    margin-top: 5rem;
    ${theme.breakpoints.between(
      theme.breakpoints.values.sm,
      theme.breakpoints.values.md
    )} {
      padding-right: 3rem;
      padding-left: 3rem;
    }
    h1 {
      ${'' /* font-family: Whyte-Inktrap, Helvetica, sans-serif, sans-serif; */}
      font-size: 3.75rem;
      font-weight: normal;
      line-height: 4rem;
      margin-bottom: 2.5rem;
      ${theme.breakpoints.up('sm')} {
        font-size: 7rem;
        line-height: 5rem;
      }
      ${theme.breakpoints.up('md')} {
        font-size: 8rem;
        line-height: 8.5rem;
      }
      ${theme.breakpoints.up('lg')} {
        font-size: 10rem;
      }
    }

    .bogue-font {
      font-family: Bogue-Bold, Garamond, Baskerville, sans-serif;
      font-size: 3.75rem;
      line-height: 4rem;
      letter-spacing: -2px;
      font-weight: normal;
      ${theme.breakpoints.up('sm')} {
        font-size: 7rem;
        line-height: 7rem;
      }
      ${theme.breakpoints.up('md')} {
        font-size: 8rem;
        line-height: 9.5rem;
      }
      ${theme.breakpoints.up('lg')} {
        font-size: 10.5rem;
      }
    }
  }
`;

export const backgroundDotStyles = (theme) => css`
  background-image: radial-gradient(
    #e8e8e8 10%,
    ${theme.palette.supply.cream.main} 10%
  );
  background-position: 0 0;
  background-size: 60px 60px;
  height: 90%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const backgroundStyles = (theme) => css`
  background-color: ${theme.palette.supply.cream.main};
  position: relative;
`;

export const dragStyles = (theme) => css`
  ${theme.breakpoints.down(theme.breakpoints.values.tablet)} {
    display: none;
  }
`;

export default styles;

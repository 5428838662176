import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Container, Typography, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { graphql, Link } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types';
// import styles from './blog-post.styles';
import styles, {
  blogHeroStyles,
  backgroundStyles,
  backgroundDotStyles,
} from './blog-hero-styles';

import Layout from '../components/layout/layout';
// import Img from 'gatsby-image';
// import SEO from '../components/seo';

// https://www.gatsbyjs.com/blog/how-to-use-the-contentful-rich-text-field-with-gatsby/

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      headerOne
      bigphotowithcaption {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 2000)
            __typename
          }
        }
      }
      # headerSectionTwo {
      #   raw
      # }
      subHeaderOne {
        childMarkdownRemark {
          html
        }
      }
      mainCopy {
        raw
      }
      twoUpPhotoWithText {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 1000)
            __typename
          }
        }
      }
      twoUpPhotoWithTextRight {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 1000)
            __typename
          }
        }
      }
      threeUpLeft {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 1000)
            __typename
          }
        }
      }
      threeUpMiddle {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 1000)
            __typename
          }
        }
      }
      threeUpRight {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 1000)
            __typename
          }
        }
      }
      imageRight {
        url
      }
      textRight {
        raw
      }
      textLeft {
        raw
      }
      imageLeft2 {
        url
      }
    }
  }
`;

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => (
  <Typography variant="body1" className="align-center">
    {children}
  </Typography>
);

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData } = node.data.target;
      return <GatsbyImage image={getImage(gatsbyImageData)} />;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data;
      return (
        <a href={uri} className="underline">
          {children}
        </a>
      );
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <Typography variant="h2">{children}</Typography>;
    },
  },
};

const BlogPost = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(802));

  return (
    <Layout>
      <div style={{ position: 'relative' }}>
        <Container maxWidth={false} css={blogHeroStyles} id="section0">
          <Container maxWidth="lg" className="side-padding">
            <div className="img-container">
              <img src={`/images/logo-chat.png`} alt="The Supply" />
            </div>
            <Grid container css={backgroundStyles}>
              <Grid item xs={12} className="rich-text-section">
                <Typography variant="h1" className="bogue-font">
                  {data.contentfulBlogPost?.headerOne &&
                    data.contentfulBlogPost.headerOne}
                </Typography>
                {data.contentfulBlogPost?.subHeaderOne && (
                  <div
                    className="subhead"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.contentfulBlogPost.subHeaderOne.childMarkdownRemark
                          .html,
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Container>
        </Container>
        <div css={backgroundDotStyles}></div>
      </div>

      <Container maxWidth={false} className="section-padding" css={styles}>
        <Container maxWidth="lg" className="side-padding">
          <Grid container spacing={isMobile ? 0 : 4} justify="center" xs={12}>
            {/* <Grid item xs={12}>
              {data.contentfulBlogPost?.headerSectionTwo &&
                renderRichText(
                  data.contentfulBlogPost.headerSectionTwo,
                  options
                )}
            </Grid> */}

            {data.contentfulBlogPost?.mainCopy && (
              <Grid item xs={12} className="limit-width">
                {renderRichText(data.contentfulBlogPost.mainCopy, options)}
              </Grid>
            )}

            {data.contentfulBlogPost?.bigphotowithcaption && (
              <Grid
                item
                xs={12}
                className="big-photo-section top-bottom-spacing"
              >
                {renderRichText(
                  data.contentfulBlogPost.bigphotowithcaption,
                  options
                )}
              </Grid>
            )}
          </Grid>

          {data.contentfulBlogPost?.twoUpPhotoWithTextRight &&
          data.contentfulBlogPost?.twoUpPhotoWithText ? (
            <Grid
              container
              spacing={isMobile ? 0 : 4}
              className="two-up top-bottom-spacing"
              xs={12}
            >
              <Grid item xs={12} sm={6}>
                {renderRichText(
                  data.contentfulBlogPost.twoUpPhotoWithText,
                  options
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {renderRichText(
                  data.contentfulBlogPost.twoUpPhotoWithTextRight,
                  options
                )}
              </Grid>
            </Grid>
          ) : null}

          {data.contentfulBlogPost?.threeUpLeft &&
          data.contentfulBlogPost?.threeUpMiddle &&
          data.contentfulBlogPost?.threeUpRight ? (
            <Grid
              container
              spacing={isMobile ? 0 : 6}
              className="top-bottom-spacing"
              xs={12}
            >
              <Grid item xs={12} sm={4}>
                {renderRichText(data.contentfulBlogPost.threeUpLeft, options)}
              </Grid>
              <Grid item xs={12} sm={4}>
                {renderRichText(data.contentfulBlogPost.threeUpMiddle, options)}
              </Grid>
              <Grid item xs={12} sm={4}>
                {renderRichText(data.contentfulBlogPost.threeUpRight, options)}
              </Grid>
            </Grid>
          ) : null}

          {data.contentfulBlogPost?.imageLeft2 ? (
            <Grid
              container
              spacing={isMobile ? 0 : 6}
              className="top-bottom-spacing"
              xs={12}
            >
              <Grid item xs={12} sm={7} className="img-lr">
                <img
                  src={data.contentfulBlogPost.imageLeft2.url}
                  className="imgWidth"
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                {renderRichText(data.contentfulBlogPost.textRight, options)}
              </Grid>
            </Grid>
          ) : null}

          {data.contentfulBlogPost?.imageLeft2 ? (
            <Grid
              container
              spacing={isMobile ? 0 : 6}
              className="top-bottom-spacing"
              xs={12}
            >
              <Grid item xs={12} sm={5}>
                {renderRichText(data.contentfulBlogPost.textLeft, options)}
              </Grid>
              <Grid item xs={12} sm={7} className="img-lr">
                <img src={data.contentfulBlogPost.imageRight.url} />
              </Grid>
            </Grid>
          ) : null}
        </Container>
      </Container>
    </Layout>
  );
};

export default BlogPost;
